define("discourse/plugins/discourse-ford-plugin/discourse/connectors/admin-user-controls-after/admin-send-password-reset", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _service, _dButton, _ajax, _ajaxError, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    async sendPasswordReset(username) {
      if (this.args.outletArgs.model.staff) {
        this.dialog.alert(_I18n.default.t("admin.ford_password_reset.other_staff"));
        return;
      }
      if (!this.currentUser.email) {
        await this.currentUser.checkEmail();
      }
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("admin.ford_password_reset.description", {
          username,
          email: this.currentUser.email
        }),
        didConfirm: () => {
          return (0, _ajax.ajax)(`/admin/ford_staff_password_reset/${username}.json`, {
            type: "POST"
          }).then(() => this.dialog.alert(_I18n.default.t("admin.ford_password_reset.success"))).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "sendPasswordReset", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @action={{fn this.sendPasswordReset @outletArgs.model.username}}
          @icon="key"
          @label="admin.ford_password_reset.title"
          class="ford-password-reset"
        />
      
    */
    {
      "id": "+YKT1ImN",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"ford-password-reset\"]],[[\"@action\",\"@icon\",\"@label\"],[[28,[32,1],[[30,0,[\"sendPasswordReset\"]],[30,1,[\"model\",\"username\"]]],null],\"key\",\"admin.ford_password_reset.title\"]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ford-plugin/discourse/connectors/admin-user-controls-after/admin-send-password-reset.js",
      "scope": () => [_dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = _default;
});